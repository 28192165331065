import { defineStore } from 'pinia'

const config = useRuntimeConfig()

interface UserPayloadInterface {
  email: string;
  password: string;
}

export interface UserState {
  id: string;
  token: string;
  email: string;
  displayName: string;
  company: string;
  verificationCode: string;
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as UserState | null,
    loading: false,
  }),
  actions: {
    async authenticateUser({ email, password }: UserPayloadInterface) {
      // useFetch from nuxt 3
      const { data, pending }: any = await useFetch('/api/auth/signin', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: {
          email,
          password,
        },
      })
      this.loading = pending

      if (data.value) {
        this.user = {
          id: data?.value?.id,
          token: data?.value?.token,
          displayName: data?.value?.displayName,
          email: data?.value?.email,
          company: data?.value?.company,
          verificationCode: data?.value?.verificationCode
        }

        const token = useCookie(config.public.authCookieName, { maxAge: 60 * 60 * 24 * 7 }) // useCookie new hook in nuxt 3
        token.value = JSON.stringify(this.user)
      }
    },
    logUserOut() {
      const token = useCookie(config.public.authCookieName, { maxAge: 60 * 60 * 24 * 7 }) // useCookie new hook in nuxt 3
      token.value = null // clear the token cookie
      this.user = null
    }
  },
})